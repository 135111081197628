export const spacing = {
  none: '0px',
  '7xs': '2px',
  '6xs': '4px',
  '5xs': '8px',
  '4xs': '12px',
  '3xs': '16px',
  '2xs': '20px',
  xs: '24px',
  sm: '32px',
  md: '40px',
  lg: '48px',
  xl: '56px',
  '2xl': '64px',
  '3xl': '72px',
  '4xl': '80px',
  '5xl': '104px',
  '6xl': '120px',
};
