export const colors = {
  gray: {
    70: '#232C39',
    60: '#333D4B',
    50: '#4E5968',
    40: '#8490A0',
    30: '#C2C9D0',
    20: '#EFF1F3',
    10: '#F7F8FA',
  },
  purple: {
    80: '#4B25B5',
    40: '#855AFF',
    30: '#B69CFF',
    20: '#E7DEFF',
    10: '#F3EEFF',
  },
  red: {
    60: '#F63C3C',
    50: '#FF5353',
    30: '#FF6F6F',
    20: '#FFECEC',
    10: '#FFF6F6',
  },
  blue: {
    60: '#003EB3',
    50: '#435FD0',
    55: '#1677FF',
    35: '#6884F6',
    30: '#69B1FF',
    20: '#BAE0FF',
    10: '#E6F4FF',
  },
  current: 'currentColor',
};
